import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RESULT_PDF, LAB_ID, CONFIG } from "constant";
import { Storage } from "aws-amplify";
import { env, delay } from "utils";
import { RESULT_PDF_BUCKET, RESULT_PDF_URL, RESULT_REQ_URL } from "./resultPdfConstant";


const handleFileError = async ({ error, attempt, maxRetries, retryDelays, rejectWithValue }) => {
  const status = error?.$metadata?.httpStatusCode || error.status || error.response?.status;
  const errorName = error?.name;

  const isRetryable =
    ["NoSuchKey", "AccessDenied"].includes(errorName) || [403, 404].includes(status);

  if (isRetryable && attempt < maxRetries) {
    await delay(retryDelays[attempt - 1] || 20000);
    return "";
  }

  if (status === 403) return "Access forbidden: Unable to retrieve the file."
  if (status === 404) return "File not found.";
  return "An unexpected error occurred.";
};


const getFilePath = ({ test, isResultPdf }) => {
  const basePath = `req_form/${env}/${test?.clientID}/${test?.orderNo}`;
  const combinedPath = test?.isCombinedTest ? `${basePath}/${test?.testName}.pdf` : `${basePath}.pdf`;

  if (CONFIG.whiteLabelID === LAB_ID.inc) {
    return `req_form/${env}/${test?.barcode || test?.orderNo}.pdf`;
  }

  if (isResultPdf) {
    const fileName = test?.resultPDF || `${test?.barcode}.pdf`;
    return test?.testDoneModalCall
      ? `results/${env}/${test?.clientID}/${test?.sequenceNo}.pdf`
      : `results/${env}/${test?.clientID}/${fileName}`;
  }

  return combinedPath;
};

const fetchPdf = async ({ test, isResultPdf, maxRetries, retryDelays, rejectWithValue }) => {
  const filePath = getFilePath({ test, isResultPdf });

  for (let attempt = 1; attempt <= maxRetries; attempt++) {
    try {
      // Attempt to download the file
      await Storage.get(filePath, {
        download: true,
        contentDisposition: "inline",
        bucket: RESULT_PDF_BUCKET,
        contentType: "application/pdf",
      });

      const fileUrl = await Storage.get(filePath, {
        download: false,
        contentDisposition: "inline",
        bucket: RESULT_PDF_BUCKET,
        contentType: "application/pdf",
      });

      const resultPdfTitle = isResultPdf
        ? `Result For : ${test?.barcode || test?.sequenceNo}`
        : `Requisition Form - ${test?.barcode || `Order No: ${test?.orderNo}`}`;

      return {
        resultFileUrl: fileUrl,
        showPdf: true,
        resultFileType: "pdf",
        resultPdfTitle,
        isLoadingResultPdf: false,
        resultPdfError: null,
      };
    } catch (error) {
      const shouldRetry = await handleFileError({ error, attempt, maxRetries, retryDelays, rejectWithValue });
      if (shouldRetry){
        return {
          resultFileUrl: "",
          showPdf: false,
          resultFileType: "pdf",
          resultPdfTitle: "",
          isLoadingResultPdf: false,
          resultPdfError: null,
        };
      }
    }
  }
};

const maxRetries = 3;

const retryDelays = [10000, 15000, 20000];

export const showResultPdf = createAsyncThunk(
  RESULT_PDF_URL,
  async (test, { rejectWithValue }) => {
    return await fetchPdf({ test, isResultPdf: true, maxRetries, retryDelays, rejectWithValue });
  }
);


export const showResultReqForm = createAsyncThunk(
  RESULT_REQ_URL,
  async (test, { rejectWithValue }) => {
    return await fetchPdf({ test, isResultPdf: false, maxRetries, retryDelays, rejectWithValue });
  }
);

const initialState = {
  pdfData: {
    resultFileUrl: "",
    showPdf: false,
    resultFileType: "pdf",
    resultPdfTitle: "",
    isLoadingResultPdf: false,
    resultPdfError: null,
  },
};

const resultPdfSlice = createSlice({
  name: RESULT_PDF,
  initialState,
  reducers: {
    hideResultPdf: () => initialState,
  },
  extraReducers: (builder) => {
    const handlePending = (state) => {
      state.pdfData.isLoadingResultPdf = true;
      state.pdfData.resultPdfError = null;
      state.pdfData.showPdf = true;
    };

    const handleFulfilled = (state, action) => {
      state.pdfData = action.payload;
    };

    const handleRejected = (state, action) => {
      state.pdfData.isLoadingResultPdf = false;
      state.pdfData.resultPdfError = action.payload || "Something went wrong";
      state.pdfData.showPdf = false;
    };

    builder
      .addCase(showResultPdf.pending, handlePending)
      .addCase(showResultPdf.fulfilled, handleFulfilled)
      .addCase(showResultPdf.rejected, handleRejected);

    builder
      .addCase(showResultReqForm.pending, handlePending)
      .addCase(showResultReqForm.fulfilled, handleFulfilled)
      .addCase(showResultReqForm.rejected, handleRejected);
  },
});

export const { hideResultPdf } = resultPdfSlice.actions;

export default resultPdfSlice.reducer;
