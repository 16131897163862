import React, { useMemo, useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import MainTable from "components/Table/MainTable";
import { ARCHIVE_STATUS } from "constant";
import Status from "components/Status";
import { useDispatch, useSelector } from "react-redux";
import { setArchivedList } from "store/features/general/generalSlice";
import { archivedListSelector } from "store/features/general/generalSelectors";
const ArchiveModal = () => {
  const { personalization, archivedList, modalTitle } = useSelector(archivedListSelector);
  const dispatch = useDispatch();
  const [sortDescending, setSortDescending] = useState(true);
  const [sortBy, setSortBy] = useState("");
  const [sortedList, setSortedList] = useState(archivedList); // Derived state for sorted data
  // Sorting logic
  const handleClose = () => {
    dispatch(
      setArchivedList({
        personalization: [],
        archivedList: [],
      })
    );
  };
  const sortArchiveStatusList = (list, sortBy, sortDescending) => {
    if (!sortBy) return list; // If no sort key is defined, return the original list.

    return [...list].sort((a, b) => {
      const valueA = a[sortBy] ?? ""; // Access the sorting key safely.
      const valueB = b[sortBy] ?? "";

      if (sortDescending) {
        return valueB < valueA ? 1 : valueA < valueB ? -1 : 0;
      } else {
        return valueB > valueA ? 1 : valueA > valueB ? -1 : 0;
      }
    });
  };

  // Effect for handling sorting
  useEffect(() => {
    setSortedList(sortArchiveStatusList(archivedList, sortBy, sortDescending));
  }, [archivedList, sortBy, sortDescending]);
  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(!sortDescending);
  };

  return (
    <Modal show animation={true} className="seperateModal" size="xl" centered onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {modalTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "#f2f2f2" }}>
        <div
          className="table-full-width px-1 desktop-noScroll mt-0 pt-0"
          style={{
            maxHeight: "300px", // Adjust height as needed
            maxWidth: "100%", // Adjust width as needed
            overflowX: "auto", // Enable horizontal scrolling
            overflowY: "auto", // Enable vertical scrolling
            backgroundColor: "#f2f2f2",
          }}
        >
          <MainTable
            // widthToSkip={155}
            trDataWrapperHeader="bg-white"
            columns={personalization}
            rows={sortedList}
            flipSort={flipSort}
            sortBy={sortBy}
            sortDescending={sortDescending}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="profileButton btn-fill createButtons"
          style={{
            marginRight: 10,
            marginTop: 10,
          }}
          variant="secondary"
          onClick={() => handleClose()}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ArchiveModal;
