import API from "api";
import Filter from "components/Filter";
import Icon from "components/Icon";
import { AppContext } from "context/app-context";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import TestTypeModal from "views/TestTypeSetting/TestTypeModal";
import Loader from "../../components/Loader/Loader";
import {
  calculateTdWidth,
  loadReqFormTest,
  formatNumber,
  draggablePersonalizationLocalStorage,
  personalisationInLC,
} from "../../utils";
import { t } from "stringConstants";

import TestDetailsModal from "./TestDetailsModal";
import MainTable from "components/Table/MainTable";
import { TEST_MANAGEMENT_PERSONALIZATION } from "constants/personalization";
import { TABLE_QUICK_TOOLS } from "constant";
import { TEST_ASSAY_LOGS_SLUG } from "constant";
import { CONFIG, TESTS_MANAGEMENT } from "constant";

import { userGivenPermission } from "store/features/authentication/authenticationSelectors";
import { useDispatch, useSelector } from "react-redux";

import { getTestAssociatePanel } from "store/features/testAssay/testAssaySlice";
import Status from "components/Status";

import { showConfirmationModal } from "store/features/general/generalAction";
import { setClearConfirmation } from "store/features/general/generalSlice";
import { panelSelector } from "store/features/testAssay/testAssaySelector";
import { PERSONALISATION_KEY } from "../../constant";
import PersonalizationModal from "components/Modal/personalizationModal";
import { customIsEmpty } from "util/customLodash";

const TestTypeSetting = () => {
  const componentRef = useRef();
  const mainWidth = useRef();
  const [width, setWidth] = useState(0);
  const appContext = useContext(AppContext);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [testSources, setTestSources] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const permission = useSelector(userGivenPermission);
  const [filterListData, setFilterList] = useState([]);
  const testElements = useSelector(panelSelector);
  const [filterTabIndex, setFilterTabIndex] = useState(0);
  const [sortBy, setSortBy] = useState("");

  const [sortDescending, setSortDescending] = useState(true);

  const [personalize, setPersonalize] = useState([]);

  const [openPersonalizationModal, setOpenPersonalizationModal] = useState(false);

  const user = appContext?.user;

  const [openDetailModal, setOpenDetailModal] = useState(false);

  const [testPanelData, setTestPanelData] = useState(null);

  const searchTerms = ["Name"];

  const dispatch = useDispatch();

  const getTestsPanesl = async () => {
    dispatch(getTestAssociatePanel());
  };

  const setPersonalizeOnLoad = () => {
    const response = draggablePersonalizationLocalStorage.get(
      user,
      PERSONALISATION_KEY.PANEL,
      TEST_MANAGEMENT_PERSONALIZATION
    );
    setPersonalize(response);
  };

  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => handleResize(), [mainWidth]);

  const changeSort = ({ dataList, sortBy, sortDescending = false }) => {
    const sortedList = [...dataList].sort((a, b) => {
      let aValue, bValue;

      if (sortBy === "icdCodes") {
        aValue = Array.isArray(a.ictCodes) && a.ictCodes.length > 0 ? a.ictCodes[0].code : "";
        bValue = Array.isArray(b.ictCodes) && b.ictCodes.length > 0 ? b.ictCodes[0].code : "";
      } else if (sortBy === "source") {
        const groupA = Array.isArray(a.sources) ? (a.sources.length === 1 ? 1 : 2) : 2;
        const groupB = Array.isArray(b.sources) ? (b.sources.length === 1 ? 1 : 2) : 2;

        if (groupA !== groupB) {
          return groupA - groupB;
        }
        const getLabel = (obj) =>
          Array.isArray(obj.sources) && obj.sources.length > 0 ? obj.sources[0].label.toLowerCase() : "";

        aValue = getLabel(a);
        bValue = getLabel(b);
      } else if (sortBy === "lastUsed") {
        // Convert date string into a Date object for proper sorting
        const parseDate = (dateStr) => (dateStr ? new Date(dateStr) : null);

        aValue = parseDate(a.lastUsed);
        bValue = parseDate(b.lastUsed);

        // If either value is null, push nulls to the end
        if (!aValue && !bValue) return 0;
        if (!aValue) return sortDescending ? -1 : 1;
        if (!bValue) return sortDescending ? 1 : -1;

        // Compare dates
        return sortDescending ? bValue - aValue : aValue - bValue;
      } else {
        // Default dynamic sorting
        aValue = a[sortBy];
        bValue = b[sortBy];
      }

      // Ensure comparison is always between strings
      return sortDescending
        ? String(bValue).localeCompare(String(aValue))
        : String(aValue).localeCompare(String(bValue));
    });

    setFilterList(sortedList);
  };

  useEffect(() => {
    changeSort({ dataList: filterListData, sortBy, sortDescending });
  }, [sortBy, sortDescending]);

  const tdWidth = calculateTdWidth(width - 50, 5);

  const [loading, setLoading] = useState();
  const [list, setList] = useState([]);

  const [editObj, setEditObj] = useState(null);

  const getList = async (filtersData) => {
    const apiData = await API.getTestTypes(filtersData);

    setList(apiData);
    setFilterList(apiData);
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filters).length === 0) return targetArray;

    const filterKeys = Object.keys(filters);

    const models = targetArray.filter((obj) => {
      return filterKeys.every((key) => {
        if (!filters[key]) return true;
        if (typeof filters[key] === "string" && !filters[key].trim()) return true;
        if (Array.isArray(filters[key]) && filters[key].length === 0) return true;

        if (key === "isActive") {
          const filterIsActive = filters[key].toLowerCase() === "active";
          return obj[key] === filterIsActive;
        }
        if (key === "isARKStone") {
          const filterIsArcstone = filters[key] === "Processed";
          return obj[key] === filterIsArcstone;
        }
        if (key === "source") {
          return (
            Array.isArray(obj.sources) &&
            Array.isArray(filters[key]) &&
            filters[key].some((selectedSource) => obj.sources.some(({ value }) => value === selectedSource))
          );
        }

        if (key === "lastUsed") {
          if (customIsEmpty(filters[key])) return obj;

          const start = new Date(filters[key].startDate).getTime();
          const end = new Date(filters[key].endDate).getTime();
          const lastUsedTime = new Date(obj[key]).getTime();

          return lastUsedTime >= start && lastUsedTime <= end;
        }
        if (Array.isArray(filters[key])) {
          return obj[key] && filters[key].indexOf(obj[key]) !== -1;
        }
        return (
          typeof obj[key] === "string" &&
          typeof filters[key] === "string" &&
          obj[key]?.toLowerCase().includes(filters[key]?.toLowerCase())
        );
      });
    });

    return models;
  };

  useEffect(() => {
    if (filter) {
      const filteredData = nestedFilter(list, filter);
      setFilterList(filteredData);
    } else {
      getList();
    }
  }, [filter]);

  useEffect(() => {
    setPersonalizeOnLoad();
    getSourceType();
    getList();
    getTestsPanesl();
  }, []);

  const getSourceType = async () => {
    const sources = await API.getTestSources();
    setTestSources(sources);
  };

  const handleEdit = (item) => {
    setEditObj(item);
  };

  const addCPTInTargetRanges = (list) => {
    const cptLookup = testElements.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {});

    return list.map((item) => ({
      ...item,
      cpt: cptLookup[item.id]?.cpt,
      antibioticResistanceType: cptLookup[item.id]?.antibioticResistanceType,
    }));
  };

  const handleSave = async (item) => {
    const obj = { ...item, targetRanges: addCPTInTargetRanges(item?.targetRanges) };

    setEditObj(null);
    setLoading(true);
    try {
      let res;
      let message = "Panel created successfully";
      let logObj = {
        updatedBy: appContext.user?.sub,
        updatedByName: appContext.user?.name,
        userID: appContext.user?.sub,
        userName: appContext.user?.name,
      };

      if (item.isNew) {
        res = await API.addTestTypes(obj);

        logObj = {
          ...logObj,
          testID: res.id,
          slug: "Create",
          newValue: TEST_ASSAY_LOGS_SLUG.TEST_CREATED,
        };
      } else {
        res = await API.updateTestTypes(obj);
        logObj = {
          ...logObj,
          testID: res.id,
          slug: "Update",
          newValue: TEST_ASSAY_LOGS_SLUG.TEST_UPDATED,
          changeObject: res,
        };
        message = "Panel updated successfully";
      }

      await API.addLogs(logObj);

      const updatedList = await API.getTestTypes();

      // const formattedTests = await loadReqFormTest({ clientID: CONFIG.clientID, tests: updatedList });
      // dispatch(updateTestNames({ tests: formattedTests }));

      setLoading(false);
      setList(updatedList);
      setFilterList(updatedList);
      appContext.showSuccessMessage(message);
    } catch (error) {
      setEditObj(obj);
      appContext.showErrorMessage(error.message);
    }
    setLoading(false);
  };

  const handleClose = async (newSource) => {
    setShowSourceModal(null);

    if (newSource) {
      appContext.showSuccessMessage("Source added successfully");
      setTestSources([...testSources, newSource]);
    }
  };

  const handleCloseTestTypeModal = async () => {
    setEditObj(null);
    setList(await API.getTestTypes());
  };

  const handleSaveTestDetail = async (testDetailsData) => {
    const { isNew } = testDetailsData;
    const action = isNew ? "create" : "update";
    setLoading(true);
    try {
      // Not save the Pathegon on base table because it is being customized for this test
      // const result = await (isNew ? createTestsDetails : updateTestsDetails)(testDetailsData);

      const updatedPathegons = editObj?.targetRanges?.map((item) =>
        item.id === testDetailsData.id ? testDetailsData : item
      );

      setEditObj((prevEditObj) => {
        const itemExists = prevEditObj?.targetRanges?.some((item) => item.id === testDetailsData.id);

        const updatedTargetRanges = itemExists
          ? prevEditObj?.targetRanges?.map((item) => (item.id === testDetailsData.id ? testDetailsData : item))
          : [...(prevEditObj?.targetRanges || []), testDetailsData];

        return {
          ...prevEditObj,
          targetRanges: updatedTargetRanges,
        };
      });

      if (!editObj.isNew) {
        try {
          await API.updateTestPathegon({ id: editObj.id, targetRanges: updatedPathegons });
        } catch (error) {
          console.error("Failed to update Test Pathegon:", error);
        }
      }

      setOpenDetailModal(false);
      setTestPanelData(null);
      setLoading(false);
    } catch (error) {
      console.error("Error", error);
      setLoading(false);

      appContext.showErrorMessage(`Unable to ${action} panel test.`);
    }
  };

  const handleSetTestPanelData = (data) => {
    if (data?.id && data.currentRanges) {
      setEditObj((prevEditObj) => {
        const exists = [...data.currentRanges];
        return {
          ...prevEditObj,
          typeOfTest: data.typeOfTest,
          targetRanges: exists,
        };
      });
    }
    const copyObject = { ...data };
    delete copyObject.currentRanges;
    setTestPanelData(copyObject);
  };

  const getIctLabelAndTitle = (ictCodes) => {
    const codes = ictCodes?.map((item) => item.code) || [];

    const ictLabel = !codes || codes.length === 0 ? "" : codes.length === 1 ? codes[0] : "Multi";

    const title = !codes || codes.length === 0 ? "" : codes.length === 1 ? codes[0] : codes.join(", ");

    return { ictLabel, title };
  };

  const getSources = (sources) => {
    let sourceTitle;
    let source;
    if (Array.isArray(sources)) {
      if (sources.length === 1) {
        sourceTitle = sources[0]?.label;
        source = sources[0]?.label;
      } else {
        sourceTitle = sources.map((item) => item.label).join(", ");
        source = "Multi";
      }
    } else {
      sourceTitle = "";
      source = sources?.label;
    }
    return { sourceTitle, source };
  };

  const ImageTD = ({ row, item }) => {
    const testStatusColor = row[item.itemKey] ? "green" : "maroon";

    return (
      <td
        key={item.id}
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
          cursor: "pointer",
        }}
      >
        <Status type="circle" size="md" color={testStatusColor} crossIcon={testStatusColor} />
      </td>
    );
  };

  const customRenderTD = (item, row) => {
    const { ictLabel, title } = getIctLabelAndTitle(row?.ictCodes);
    const { sourceTitle, source } = getSources(row?.sources);
    if (item.itemKey === "icdCodes") {
      return (
        <td
          key={item.id}
          className="ellipsis"
          style={{
            textAlign: item.textAlign,
            textOverflow: item.textOverflow,
            cursor: "pointer",
          }}
          title={title}
        >
          {ictLabel}
        </td>
      );
    }

    if (item.itemKey === "isARKStone" || item.itemKey === "isActive") {
      return ImageTD({ row, item });
    }

    if (item.itemKey === "source") {
      return (
        <td
          key={item.id}
          className="ellipsis"
          style={{
            textAlign: item.textAlign,
            textOverflow: item.textOverflow,
            cursor: "pointer",
          }}
          title={sourceTitle}
        >
          {source}
        </td>
      );
    }
  };

  const handleCellClick = (key, row, event) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.edit:
        handleEdit(row);
        break;
      default:
        break;
    }
  };

  const handleArchive = async (item) => {
    dispatch(setClearConfirmation());
    try {
      setLoading(true);
      const res = await API.archiveTestType(item);

      const logObj = {
        updatedBy: appContext.user?.sub,
        updatedByName: appContext.user?.name,
        userID: appContext.user?.sub,
        userName: appContext.user?.name,
        testID: res.id,
        slug: "Archived",
        newValue: TEST_ASSAY_LOGS_SLUG.TEST_ARCHIVED,
        changeObject: res,
      };

      await API.addLogs(logObj);

      const updatedList = await API.getTestTypes();

      // const formattedTests = await loadReqFormTest({ clientID: CONFIG.clientID, tests: updatedList });

      setLoading(false);
      setList(updatedList);
      setFilterList(updatedList);
      appContext.showSuccessMessage("Test Archived successfully");

      // dispatch(updateTestNames({ tests: formattedTests }));
    } catch (ex) {
      console.log("Ex", ex);
      setLoading(false);
    }
  };

  const handlePersonalization = async (data) => {
    setPersonalize([...data]);
    setOpenPersonalizationModal(false);
    const personalisationData = personalisationInLC.saveAs(data, PERSONALISATION_KEY.PANEL);
    await API.saveUserPersonalisation(appContext.user.sub, personalisationData);
  };

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  return (
    <>
      {!loading ? (
        <div className="shows-screen-parent">
          <Row className="">
            <Col md="12">
              <Card className="strpied-tabled-with-hover bg-transparent border-0">
                <Card.Header className="shows-screen-wrapper">
                  <Row className="pb-4">
                    <Col md-="12">
                      <Card.Title
                        as="h4"
                        style={{
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        Panels ({formatNumber(filterListData.length)})
                      </Card.Title>
                      <div className="shows-filter-wrapper">
                        <div className="shows-filter-inner">
                          <Icon
                            handleClick={() => setShowFilter(!showFilter)}
                            title={"Filter"}
                            label={"Filter"}
                            iconType={"filter"}
                          />
                          <Icon
                            handleClick={() => setOpenPersonalizationModal(true)}
                            title={t("personalize")}
                            label={t("personalize")}
                            iconType={"personalizeIcon"}
                          />
                          {permission[TESTS_MANAGEMENT]?.write && (
                            <Icon
                              handleClick={() =>
                                setEditObj({
                                  isNew: true,
                                  typeOfTest: "Qualitative",
                                  targetRanges: [],
                                  isActive: true,
                                })
                              }
                              title={"Create Panel"}
                              label={"Create Panel"}
                              iconType={"createIcon"}
                            />
                          )}
                        </div>
                      </div>
                      <div>
                        {showFilter && (
                          <Filter
                            filterTerms={searchTerms}
                            setFilter={setFilter}
                            filter={filter}
                            personalisationData={personalize}
                            // isGrouped={true}
                            filterTabIndex={filterTabIndex}
                            setFilterTabIndex={(value) => setFilterTabIndex(value)}
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                </Card.Header>

                <Card.Body className="table-full-width px-0 desktop-noScroll pt-0 mt-0">
                  <div className="table-responsive pendingReleaseTable">
                    <MainTable
                      // columns={TEST_MANAGEMENT_PERSONALIZATION}
                      columns={personalize}
                      rows={filterListData}
                      flipSort={flipSort}
                      sortBy={sortBy}
                      sortDescending={sortDescending}
                      // selectedRows={checkboxes}
                      tools={[TABLE_QUICK_TOOLS.edit]}
                      // personalisationKey={"orderpersonalize"}
                      // individualRowCssClass={(row) =>
                      //   row.employee_demographics?.stat && !row.result ? "trDataWrapper isStatRow" : "trDataWrapper"
                      // }
                      draggable
                      resizable
                      handleSaveDragAndResize={(personalizeArr) => {
                        setPersonalize(personalizeArr);
                      }}
                      personalisationKey={PERSONALISATION_KEY.PANEL}
                      dropDownOptions={[
                        { value: "Logs", title: "Audit Trail" },
                        { value: "Archive", title: "Archive" },
                      ]}
                      handleDropDownClick={(type, row) => {
                        if (type === "Audit Trail") {
                          appContext.showLogs({ ...row, title: "Test Management Logs", ordID: row.id });
                        } else {
                          let deletedItems = { ...row };
                          deletedItems = { ...deletedItems, isActive: false, isArchive: true, isNew: false };
                          const confirmationModalData = {
                            showModal: true,
                            onConfirm: () => handleArchive(deletedItems),
                            title: "Archive Test ",
                            message: "Are you sure, you want to archive that Test?",
                          };
                          dispatch(showConfirmationModal(confirmationModalData));
                        }
                      }}
                      customColumnCellRenderer={customRenderTD}
                      handleCellClick={handleCellClick}
                    />
                  </div>
                </Card.Body>
              </Card>
            </Col>
            {/* {CONFIG.isWhiteLabel ? <TestResultSetting /> : <LabBarCodeAlertSetting />} */}
          </Row>
        </div>
      ) : (
        <Loader />
      )}
      {editObj && (
        <TestTypeModal
          item={editObj}
          handleSave={handleSave}
          handleClose={handleCloseTestTypeModal}
          testSources={testSources}
          openDetailModal={() => setOpenDetailModal(true)}
          mainTests={list}
          setTestPanelData={(data) => handleSetTestPanelData(data)}
          isDetailModalOpen={openDetailModal}
        />
      )}
      {openDetailModal && (
        <TestDetailsModal
          data={{ ...testPanelData, callFrom: "test" }}
          handleSaveTestDetail={handleSaveTestDetail}
          loading={loading}
          handleClose={() => {
            setOpenDetailModal(false);
            setTestPanelData(null);
          }}
        />
      )}

      {openPersonalizationModal && (
        <PersonalizationModal
          data={personalize}
          handleChange={handlePersonalization}
          show={openPersonalizationModal}
          handleClose={() => setOpenPersonalizationModal(false)}
        />
      )}
    </>
  );
};

export default TestTypeSetting;
