import ErrorMessage from "components/Message/ErrorMessage";
import { useEffect, useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { getValidReg } from "utils";

import PhoneNoInput from "components/Phone/PhoneNoInput";
import { countryListAllIsoData } from "constant";
import { selectLoginUser } from "store/features/authentication/authenticationSelectors";
import { useDispatch, useSelector } from "react-redux";

import { selectedCompanySetting } from "store/features/companySetting/companySettingSelectors";

const ReferenceLabModal = (props) => {
  const { handleClose, selectItem, handleSave } = props;

  const loginUser = useSelector(selectLoginUser);
  const [error, setError] = useState("");
  const getObject = () => {
    const country = countryListAllIsoData.find((f) => f.name === selectItem.country) || { code: "US" };

    return { ...selectItem, isoCode: country.code };
  };
  const [newLocation, setNewLocation] = useState(getObject());
  const [openDefaultSetting, setOpenDefaultSetting] = useState(null);
  const dispatch = useDispatch();
  

  const { otherNPI, cliaNumber } = useSelector(selectedCompanySetting);

  const handleSaveChange = () => {
    if (!newLocation.name || !newLocation.name.trim()) {
      setError("Client name required");
      return;
    }

    // if (!newLocation.contact_name) {
    //   setError("Main Contact Name is required");
    //   return;
    // }

    // if (!newLocation.contact_email) {
    //   setError("Email is required");
    //   return;
    // }
    // if (!isValidEmail(newLocation.contact_email)) {
    //   setError("Invalid Email");
    //   return;
    // }

    // if (!newLocation.contactName) {
    //   setError("Main Contact is required");
    //   return;
    // }
    // if (!isValidPhoneWithCode(newLocation.contactName)) {
    //   setError("Invalid Phone Number");
    //   return;
    // }

    // if (!newLocation.phone_number) {
    //   setError("Main Contact Number is required");
    //   return;
    // }

    // if (!isValidPhoneWithCode(newLocation.phone_number)) {
    //   setError("Invalid Main Contact Number");
    //   return;
    // }


    handleSave(newLocation);
  };


  return (
    <Modal show backdrop="static" animation={true} onHide={() => handleClose()} centered size="md">
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {newLocation.isNew ? " Create New Reference Lab" : "Edit Reference Lab"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="createRefernceWrapper">
          <div className="leftSide">
            <div className="row mb-3">
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Lab Name</label>
                <input
                  value={newLocation?.name}
                  className="modalInput"
                  placeholder="Lab Name"
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) =>
                    setNewLocation({
                      ...newLocation,
                      name: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Main Contact Name:</label>
                <input
                  value={newLocation?.contact_name}
                  className="modalInput"
                  placeholder="Main Contact Name"
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) =>
                    setNewLocation({
                      ...newLocation,
                      contact_name: getValidReg(e.target.value),
                    })
                  }
                />
              </div>

              <div className="form-group-wrapper phoneInput position-relative">
                <label className="modalLineHeaders">Phone Number:</label>
                <PhoneNoInput
                  cssClass="w-100"
                  handleChange={(e) =>
                    setNewLocation({
                      ...newLocation,
                      phone_number: e,
                    })
                  }
                  value={newLocation?.phone_number}
                />
              </div>

              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Email:</label>
                <input
                  type="text"
                  className="modalInput"
                  placeholder="Email"
                  value={newLocation?.email}
                  onChange={(e) => {
                    setNewLocation({
                      ...newLocation,
                      email: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="headerButton btn-fill" onClick={() => handleClose()}>
          Close
        </Button>
        <div>
          {/* <Button variant="secondary" className="headerButton btn-fill me-1" onClick={() => handleOpenDefaultSetting()}>
            Open Default Setting
          </Button> */}
          <Button variant="secondary" className="headerButton btn-fill" onClick={handleSaveChange}>
            {newLocation.isNew ? "Create" : "Update"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ReferenceLabModal;
