import { TABLE_COLUMN_TYPES } from "constants/personalization";
export const ARCHIVE_PERSONALIZATION = [
  {
    itemKey: "firstName",
    title: "First Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 1,
    type: TABLE_COLUMN_TYPES.firstLetterCapital,
    columnWidth : "60px"

  },
  
  {
    itemKey: "lastName",
    title: "Last Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 2,
    type: TABLE_COLUMN_TYPES.firstLetterCapital,
    columnWidth : "60px"

  },
  {
    itemKey: "status",
    title: "Status",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 3,
    columnWidth : "60px",
    type :TABLE_COLUMN_TYPES.text,
    customCellRenderer : false
  },
  
  {
    itemKey: "message",
    title: "Reason",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 4,
    type: TABLE_COLUMN_TYPES.text,
    columnWidth : "60px"
  },
];
